import { Component, ViewChild, ElementRef, AfterViewInit, OnInit } from '@angular/core';
import { LlInfoRequestsService } from '../ll-info-requests.service';
import { LlScrollpaneService } from '../ll-scrollpane.service';
import { LlPermissionsService } from '../ll-permissions.service';
import { jqxPanelComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxpanel'
import { jqxPopoverComponent } from "jqwidgets-scripts/jqwidgets-ts/angular_jqxpopover";
import { jqxTextAreaComponent } from "jqwidgets-scripts/jqwidgets-ts/angular_jqxtextarea";
import { fromEvent } from 'rxjs';
import { filter } from "../utility-search";
import { removeCarriageReturn } from "../utility";

@Component({
  selector: 'll-info-request-visits-sp',
  templateUrl: './ll-info-request-visits-sp.component.html',
  styleUrls: ['../cssMain.css']
})

export class LlInfoRequestVisitsSpComponent implements OnInit {

  @ViewChild('mainDiv_ll_info_request_visits_sp') mainDiv: ElementRef;
  @ViewChild('vertTab_ll_info_request_visits_sp') private vertTab: ElementRef;
  @ViewChild('scrollpane_ll_info_request_visits_sp') private SP: jqxPanelComponent;
  @ViewChild('popover_search') private popover_search: jqxPopoverComponent;
  @ViewChild('text_search') text_search: jqxTextAreaComponent;

  constructor(
    private LlInfoRequestsService: LlInfoRequestsService,
    private LlScrollpaneService: LlScrollpaneService,
    private LlPermissionsService: LlPermissionsService,

  ) { }

  bundle = {
    lable: "Info. Requests",
    prefix: "CT",
    selectedId: -1,
    selectedId_ondeck: -1,
    //showObj: false,
    showObj: true,
    showRemaining: false,
    scrolling: false,
    canAdd: false,
    canEdit: false,
    canFilter: true,
    canViewGraphs: false,
    viewGraph: false,
    addOrRemove:'',

    searchText: "",
    function:'',
    LlInfoRequestsService: this.LlInfoRequestsService,
    records: [],
    origionalRecords: [],
    parentW: 0,
    parentH: 0,
    remainingX: 0,
    remainingY: 0,
    remainingW: 0,
    remainingH: 0,
    SPYPosition: 0,
    ctx: {},
    showTab: true,
    objH: 0,
    lblY: 20,
    topOffset: 0,
    spY: 0,
    spW: 175,
    spH: 0,
    spB: 0,
    lineWidth: 1,
    vertLineX: 0,
    vertLineY: -1,
    vertLineW: 20,
    vertLineH: 0,
    tabY: 0,
    tabW: 0,
    tabH: 18,
    Tooltip01: 'not Found',

  }

  ngOnInit() { }

  eventSubscription_renewVisits: any;

  ngOnDestroy() {
     this.eventSubscription_renewVisits.unsubscribe();
  }

  ngAfterViewInit() {

    fromEvent(this.mainDiv.nativeElement, 'mousemove')
      .subscribe(res => {
        let value = this.SP.getVScrollPosition();

        if (value != this.bundle.SPYPosition) {
          this.bundle.SPYPosition = value;

          this.bundle.scrolling = true;

          document.getElementById(this.bundle.prefix + this.bundle.selectedId.toString()).click();
        }
      }
      );

    fromEvent(this.mainDiv.nativeElement, 'wheel')
      .subscribe(res => {
        let value = this.SP.getVScrollPosition();

        if (value != this.bundle.SPYPosition) {
          this.bundle.SPYPosition = value;

          this.bundle.scrolling = true;

          document.getElementById(this.bundle.prefix + this.bundle.selectedId.toString()).click();
        }
      }
      );

    this.eventSubscription_renewVisits = this.LlInfoRequestsService.renewVisits.subscribe(SUBJECTVISITID => {

      this.bundle.addOrRemove = '';

      this.getData();
    });

    this.setVars();
  }

  setVars() {

    //console.log("setVars()")

    this.bundle.function = this.mainDiv.nativeElement.parentElement.id;

    this.bundle.viewGraph = false;

    this.bundle.parentW = this.mainDiv.nativeElement.parentElement.parentElement.clientWidth;
    this.bundle.parentH = this.mainDiv.nativeElement.parentElement.parentElement.clientHeight;

    if (this.bundle.function == 'll_companys_models_sp') {
      this.bundle.canAdd = this.LlPermissionsService.check("addVisits");
     
    } else if (this.bundle.function == 'll-model-functions-sp') {
      this.bundle.canViewGraphs = this.LlPermissionsService.check("viewGraphs")
    }    

    

    setTimeout(() => {

      this.LlScrollpaneService.standardDimentions(this.bundle);
      
      var canvasEl: HTMLCanvasElement = this.vertTab.nativeElement;
      canvasEl.width = this.bundle.vertLineX + this.bundle.vertLineW;
      canvasEl.height = this.bundle.vertLineH;
      this.bundle.ctx = canvasEl.getContext('2d');

      setTimeout(() => {
        this.LlScrollpaneService.standardRemainderValues(this.bundle, canvasEl);

        this.getData();
      });
    });
  }

  getData() {

    this.LlInfoRequestsService.NG_LL_infoReq_subjectVisits_get(done01, this.bundle);

    function done01(records, bundle) {

      ///console.log(records)

      bundle.records = records;
      bundle.origionalRecords = records;

      let selectedId_initial = bundle.LlInfoRequestsService.getActiveSubjectVisitId();

      setTimeout(() => {

        bundle.showObj = true;

        setTimeout(() => {

          if (selectedId_initial != null && document.getElementById(bundle.prefix + selectedId_initial.toString()) != null) {
            document.getElementById(bundle.prefix + selectedId_initial.toString()).click();
          }

        });
      });
    }
  }

  select(event) {

    //console.log("select")

    this.bundle.addOrRemove = 'none';

    this.bundle = this.LlScrollpaneService.bundle(event, this.bundle, this.SP)

    this.LlScrollpaneService.paintVertTab(this.bundle);

    if (this.bundle.scrolling == true) {

      this.bundle.scrolling = false;

    } else {

      this.bundle.showRemaining = false;

      setTimeout(() => {

        this.bundle.selectedId = this.bundle.selectedId_ondeck;
        this.LlInfoRequestsService.setActiveSubjectVisitId(this.bundle.selectedId);

        this.bundle.showRemaining = true;
      });
    }
  }

  noAction() {
    //needed HTML buttons point to this 
  }

  onKeyUp(event) {

    if (event.keyCode == 13) { ///Enter
      this.text_search.val(removeCarriageReturn(this.text_search.val()));
      this.search();
    }

    if (event.keyCode == 9) { ///Tab || Enter
      this.search();
    }
  }

  search() {

    this.popover_search.close()

    setTimeout(() => {
      this.bundle.searchText = this.text_search.val();

      if (this.bundle.searchText == "") {
        this.bundle.records = this.bundle.origionalRecords;
      } else {
        this.bundle.records = filter(this.bundle.origionalRecords, "FOLDERNAME", this.bundle.searchText);

        if (this.bundle.records.length > 0) {
          this.bundle.selectedId = this.bundle.records[0].FOLDERID;

          this.LlInfoRequestsService.setActiveSubjectVisitId(this.bundle.selectedId);

        }
      }

      this.searchClick();
    });
  }

  clear() {
    this.bundle.records = this.bundle.origionalRecords;

    ///console.log(this.bundle.records)

    this.bundle.searchText = ""
    this.text_search.val("");
    let looking = true;

    for (var i = 0; i < this.bundle.records.length; i++) {

      if (this.bundle.records[i].FOLDERID > 0 && looking == true) {

        looking = false;

        this.popover_search.close()

        this.bundle.selectedId = this.bundle.records[i].FOLDERID;

        this.LlInfoRequestsService.setActiveSubjectVisitId(this.bundle.selectedId);

        setTimeout(() => {

          //console.log("this.bundle.selectedId: " + this.bundle.selectedId)

          if (this.bundle.selectedId != null && document.getElementById(this.bundle.prefix + this.bundle.selectedId.toString()) != null) {
            document.getElementById(this.bundle.prefix + this.bundle.selectedId.toString()).click();
          }
        }, 200);
      }
    }
  }

  searchClick() {

    if (this.bundle.records.length == 0) {

      this.bundle.showTab = false;
      this.bundle.selectedId = -666

      this.LlScrollpaneService.paintVertTab(this.bundle);

    } else {

      let selectedId_local = this.bundle.records[0].FOLDERID;

      setTimeout(() => {

        if (selectedId_local != null && document.getElementById(this.bundle.prefix + selectedId_local.toString()) != null) {

          document.getElementById(this.bundle.prefix + selectedId_local.toString()).click();
        }

      });
    }

  }

  add() {
    this.bundle.showTab = false;
    this.LlScrollpaneService.paintVertTab(this.bundle);
    this.bundle.addOrRemove = 'add';
  }

  remove() {
    this.bundle.showTab = false;
    this.LlScrollpaneService.paintVertTab(this.bundle);
    this.bundle.addOrRemove = 'remove';
  }


  selectGraph() {
    this.bundle.viewGraph = true;
  }

  goBack() {
    this.bundle.viewGraph = false;
  }



}
